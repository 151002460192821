import React from 'react';
import Helmet from 'react-helmet';
import 'prismjs/themes/prism.css';
import '../global-styles';

import userConfig from '../../config';

import Layout from './layout';

import Card from '../components/Card';
import Container from '../components/Container';
import Summary from '../components/Summary';
import Pagination from '../components/Pagination';

import readingTime from 'reading-time';

const IndexPage = ({ pageContext }) => {
  const { group, index, pageCount } = pageContext;
  const previousUrl = index - 1 === 1 ? '' : (index - 1).toString();
  const nextUrl = (index + 1).toString();
  return (
    <Layout>
    <Container>
      <Helmet
        title={`${userConfig.title} | ${userConfig.author}`}
        htmlAttributes={{ lang: 'en' }}
      >
        <meta
          name="description"
          content={`${userConfig.title} | ${userConfig.description}`}
        />
      </Helmet>
      {group.map(({ node }) => {
        return <Card key={node.fields.slug}>
          <Summary
            date={node.frontmatter.date}
            title={node.frontmatter.title}
            excerpt={node.excerpt}
            image={node.frontmatter.featuredImage}
            slug={node.fields.slug}
            readingTime={readingTime(node.html)}
          />
        </Card>
        })}
      <Pagination
        isFirst={index === 1}
        isLast={index === pageCount}
        nextUrl={nextUrl}
        previousUrl={previousUrl}
      />
    </Container>
    </Layout>
  );
};
export default IndexPage;
