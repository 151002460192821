module.exports = {
  title: 'No Matter', // Required
  author: 'Andy', // Required
  description: 'Because _No Matter Where You Go, There You Are_',
  primaryColor: '#3498db', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    website: 'https://www.nomatter.press',
    github: 'https://bitbucket.org/virtualandy/nomatter',
    twitter: 'https://twitter.com/virtualandy',
  },
  // pathPrefix: '/devblog',
  siteUrl: 'https://www.nomatter.press',
};
